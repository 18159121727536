
import * as Sentry from '@sentry/browser';



Sentry.init({
  dsn: "https://2167231570d74901b73bddb0cebf0be8@sentry.io/6536",
  
  release: 'ecffb153ed0c11658053a4cdd9023fb83abd244c',
  
  linesOfContext: 500, 
  autoSessionTracking: false,
  environment: 'production',
  ignoreErrors: [ 'DOMException: WRONG_DOCUMENT_ERR', 'Non-Error promise rejection captured', 'EvalError' ]
});


window.Sentry = Sentry

